<template>
  <v-container >
    <v-card width="100%" height="auto" class="mx-auto pa-2">
      <v-layout wrap>
      <v-card outlined height="auto" class="pa-2" width="50%" >
        <v-layout wrap>
          <v-radio-group dense outlined row style="height: 30px; margin-top: 2px"
                  id="selOption" 
                  ref="selOption" 
                  v-model="selOption"
                >
                <v-radio
                  label="가동" value="live" color="#3a4f3f" @click="refreshList()"
                ></v-radio>
                <v-radio
                  label="중지" value="del" color="#88191a"  @click="refreshList()"
                ></v-radio>
                <v-radio
                  label="전체" value="all" color="black"  @click="refreshList()"
                ></v-radio>
              </v-radio-group>
              <v-spacer></v-spacer>
              <v-btn class="rounded-pill ma-0 mr-4 elevation-5 normal-btn" style="height: 35px;"
                id="refreshList" 
                ref="refreshList" 
                @click="refreshList()">
                <img src="@/assets/icon_refresh.svg" alt="새로고침" />
                새로고침
              </v-btn>
          </v-layout>
        </v-card>  
        <v-flex xs12 lg3 class="mb-2 ml-3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="농장명이나 돈방명을 입력하세요."
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-data-table dense height="650px" class="elevation-2  v-data-table1 mt-2" style="width: 100%; "
        id="items" 
        ref="items"
        :loading="loading" 
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page= "500"
        no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
        fixed-header
        hide-default-footer
        sort-by="farm"
        group-by="farm"
        show-group-by
        >
        <template v-slot:group.header="{items, isOpen, toggle}">
          <th colspan="14">
            <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon> {{ items[0].farm }}
          </th>
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialogOff" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h9">{{farm_name}}:{{room_name}}</span> &nbsp; <small>센서{{sensorID}}</small>
              </v-card-title>
              <v-card-text>
                <span style="color:#3a4f3f; font-weight:bold"> 사용중지로 전환하겠습니까?</span><br>
                <span>현재일 부터 데이타 수신, 알림 등이 중지됩니다.<br>
                간단한 메모를 작성하면 관리에 도움이 됩니다^^ </span>
                <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: 10px"
                  label="메모"
                  id="historyOff" 
                  ref="historyOff" 
                  v-model="historyOff"
                  value=""
                  persistent-hint :hint="hintOff"
                ></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#88191a" outlined text @click="dialogOff=false">취소</v-btn>
                <v-btn color="#3a4f3f" outlined text @click="offItemConfirm">확인</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogOn" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h9">{{farm_name}}:{{room_name}}</span> &nbsp; <small>센서{{sensorID}}</small>
              </v-card-title>
              <v-card-text>
                <span style="color:#3a4f3f; font-weight:bold"> 재 가동으로 전환하겠습니까?</span><br>
                <span>현재일 부터 데이타 표시, 알림등이 재가동됩니다.<br>
                간단한 메모를 작성하면 관리에 도움이 됩니다^^ </span>
                <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: 10px"
                  label="메모"
                  id="historyOn" 
                  ref="historyOn" 
                  v-model="historyOn"
                  value=""
                  persistent-hint :hint="hintOn"
                ></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red " outlined text @click="dialogOn=false">취소</v-btn>
                <v-btn color="blue" outlined text @click="onItemConfirm">확인</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.monitor="{ item }">
          <v-icon
            color="#55755d"
            @click="gotoMonitor(item)"
          >
            mdi-chart-line
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="item.deldate == ''" 
            color=#9AB643
            @click="offSensor(item)"
          >
            mdi-access-point-network
          </v-icon>
          <v-icon v-else
            color=#88191a
            @click="onSensor(item)"
          >
            mdi-access-point-network-off
          </v-icon>
        </template>
        <template v-slot:item.ellapse="{ item }">
          <v-chip label class="justify-center pa-1" style="width: 80px"
            :color=" item.ellapse > 1 ? 'red' : 'green'"
            dark
          >
            {{ item.ellapse }}&nbsp;시간
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-layout row>
      <v-icon class="mt-5 ml-3 mr-2" color=green>mdi-access-point-network</v-icon>
      <br> 사용으로 설정된 상태, 누르면 중지로 설정할 수 있습니다.<br>
      <v-icon class="mt-5 ml-3 mr-2" color=red>mdi-access-point-network-off</v-icon>
      <br> 사용중지로 설정된 상태, 누르면 사용으로 설정할 수 있습니다.<br>
    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";

export default {
  name: 'ListSensorsOnOff',

  data: () => ({
    items : [],
    headers: [
      { text: '경영체', align: 'left', sortable: true, value: '경영체', width: "130px", groupable: false, class: 'green darken-4 white--text'},
      { text: '농장/사업장', value: 'farm', width: "150px", groupable: true, class: 'green darken-4 white--text' },
      { text: '축사/건물/축사', value: 'building', width: "140px", groupable: false, class: 'green darken-4 white--text' },
      { text: '돈방/위치', value: 'room', width: "140px", groupable: false, class: 'green darken-4 white--text'  },
      { text: '센서 Mac', value: 'sensor_mac', width: "50px", groupable: false, class: 'green darken-4 white--text',divider: true},
      // { text: 'Data Monitor', align: 'center', sortable: false, value: 'monitor', width: "40px", groupable: false, class: 'green darken-4 white--text',divider: true },
      { text: '상태', align: 'center', sortable: false, value: 'actions', width: "60px", groupable: false, class: 'green darken-4 white--text',divider: true  },
      { text: '온도 ℃', align: 'center', sortable: false, value: 'temp', width: "20px", groupable: false, class: 'green darken-4 white--text'},
      { text: '습도 %', align: 'center', sortable: false, value: 'hum', width: "20px", groupable: false, class: 'green darken-4 white--text'},
      { text: 'CO₂ ppm', align: 'center', sortable: false, value: 'co2', width: "20px", groupable: false, class: 'green darken-4 white--text'},
      { text: 'NH₃ ppm', align: 'center', sortable: false, value: 'nh3', width: "20px", groupable: false, class: 'green darken-4 white--text'},
      { text: 'H₂S ppm', align: 'center', sortable: false, value: 'h2s', width: "20px", groupable: false, class: 'green darken-4 white--text',divider: true  },
      { text: '최종수신', value: 'lut', width: "200px", groupable: false, class: 'green darken-4 white--text' },
      { text: '경과시간', align: 'center', value: 'ellapse', width: "100px", groupable: false, class: 'green darken-4 white--text' },
      { text: '비고', align: 'left', value: 'history', width: "500px", groupable: false, class: 'green darken-4 white--text' },
    ],
    search: '',
    editedIndex: -1,
    dialogOn: false,
    dialogOff: false,
    loading: false,
    msgFlag: true,
    selOption: "all",

    farm_name: "",
    room_name: "",
    sensorID: "",
    sensorMac: "",
    historyOn: "",
    historyOff: "",
    hintOn: "",
    hintOff: "",


  }),
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    this.refreshList();
 },

  methods: {
    refreshList() {
      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loading = true;
      // console.log("리스트");
      Apis.listSensorsOnOff({
        selOption: this.selOption,
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.sensor_mac = Common.getMacMask(element.sensor_mac);
        });
        this.items = res.data;
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loading = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listSensorsOnOff 호출 오류",err);
        alert("listSensorsOnOff 호출 오류" + err);
        this.$router.push("/");
        this.loadingRoom = false;
      }) 
    }, 
    onSensor(item) {
      this.editedIndex = this.items.indexOf(item);
      // console.log("farm",item.farm,item.room)
      this.sensorID = item.id;
      this.sensorMac = item.sensor_mac;
      this.setDate = item.setdate;
      this.farm_name = item.farm;
      this.room_name = item.room;
      this.hintOn = item.history;
      this.dialogOn = true;
    },
    onItemConfirm () {
      this.$store.commit("resMessage","");
      this.loading = true;
      // let currentDT = dateUtil.format(new Date(), "yyyy-MM-dd");  설치일을 변경 할것인가 말것인가 ?
      Apis.updateSensorFree({
          // "id" : this.sensorID,
          "sensor_mac" :	this.sensorMac.replace(/:/gi, ''),
          "deldate" :	"",
          "history" : this.historyOn.trim() || "",
          },(res) => {  
            if (res.result) {
              this.loading = false;
              this.historyOn = "";
              this.$store.commit("resMessage","재 가동 " + res.message);
              this.items[this.editedIndex].deldate = "";
              this.dialogOn = false;
            } else {
              this.loading = false;
              alert("재 가동 오류\n" + res.message)
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSaveSensor = false;
              console.log("onItemConfirm API 호출 오류",err)
              alert(err);
          }
        )
    },
    offSensor(item) {
      this.editedIndex = this.items.indexOf(item);
      // console.log("farm",item.farm,item.room)
      this.sensorID = item.id;
      this.sensorMac = item.sensor_mac;
      this.farm_name = item.farm;
      this.room_name = item.room;
      this.hintOff = item.history;
      this.dialogOff = true;
    },
    offItemConfirm () {
      this.$store.commit("resMessage","");
      this.loading = true;
      let currentDT = dateUtil.format(new Date(), "yyyy-MM-dd");
      Apis.updateSensorFree({
          // "id" : this.sensorID,
          "sensor_mac" :	this.sensorMac.replace(/:/gi, ''),
          "deldate" :	currentDT || "",
          "history" : this.historyOff.trim() || "",
          },(res) => {  
            if (res.result) {
              this.loading = false;
              this.historyOff = "";
              this.$store.commit("resMessage"," 사용중지 " + res.message);
              this.items[this.editedIndex].deldate = currentDT;
              this.dialogOff = false;
            } else {
              this.loading = false;
              alert("offItemConfirm API 호출 오류" + res.message)
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSaveSensor = false;
              console.log("offItemConfirm API 호출 오류",err)
              alert(err);
          }
        )
    },
    gotoMonitor(item) {
      this.$router.push({
        name: "MonitorSensor",
        params: { 
          sensor: {code: item.sensor_mac.replace(/:/gi, ''), name: item.company + " " + item.room + " " + item.sensor_mac.replace(/:/gi, '') }
        }
      });
    },

  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.normal-btn{
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
